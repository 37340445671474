import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Wrapper = styled.section`
  flex-basis: 13rem;
  flex-grow: 3;
`

const FlagWrapper = styled.div`
  display: flex;
  padding: 2.5rem 0 0;

  a {
    display: block;
    margin: 0 1.5rem 1rem 0;

    .gatsby-image-wrapper {
      display: block;
      width: 24px;
      height: 15px;
    }
  }
`

export default function Contact(props) {
  const flagImages = useStaticQuery(graphql`
    query footerFlagImages {
      usa: file(relativePath: { eq: "flags/flag-us.png" }) {
        childImageSharp {
          gatsbyImageData(width: 24, placeholder: BLURRED)
        }
      }
      canada: file(relativePath: { eq: "flags/flag-ca.png" }) {
        childImageSharp {
          gatsbyImageData(width: 24, placeholder: BLURRED)
        }
      }
      korea: file(relativePath: { eq: "flags/flag-kr.png" }) {
        childImageSharp {
          gatsbyImageData(width: 24, placeholder: BLURRED)
        }
      }
      china: file(relativePath: { eq: "flags/flag-cn.png" }) {
        childImageSharp {
          gatsbyImageData(width: 24, placeholder: BLURRED)
        }
      }
      au: file(relativePath: { eq: "flags/flag-au.png" }) {
        childImageSharp {
          gatsbyImageData(width: 24, placeholder: BLURRED)
        }
      }
      eu: file(relativePath: { eq: "flags/flag-eu.png" }) {
        childImageSharp {
          gatsbyImageData(width: 24, placeholder: BLURRED)
        }
      }
      uk: file(relativePath: { eq: "flags/flag-uk.png" }) {
        childImageSharp {
          gatsbyImageData(width: 24, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <Wrapper>
      <p>We want to hear from you! Give us a call or send a message.</p>
      <Link to="/contact">Contact Us</Link>

      <FlagWrapper>
        <Link to="/">
          <GatsbyImage
            image={flagImages.usa.childImageSharp.gatsbyImageData}
            alt="US Flag"
          />
        </Link>

        <a
          href="https://www.freal.ca/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <GatsbyImage
            image={flagImages.canada.childImageSharp.gatsbyImageData}
            alt="Canadian Flag"
          />
        </a>

        <a
          href="http://www.frealkorea.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <GatsbyImage
            image={flagImages.korea.childImageSharp.gatsbyImageData}
            alt="Korean Flag"
          />
        </a>

        <a
          href="http://www.freal.com.cn/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <GatsbyImage
            image={flagImages.china.childImageSharp.gatsbyImageData}
            alt="Chinese Flag"
          />
        </a>

        <a
          href="http://freal.com.au/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <GatsbyImage
            image={flagImages.au.childImageSharp.gatsbyImageData}
            alt="Australian Flag"
          />
        </a>

        <Link to="/">
          <GatsbyImage
            image={flagImages.eu.childImageSharp.gatsbyImageData}
            alt=""
          />
        </Link>

        <a
          href="https://www.frealdrinks.co.uk/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <GatsbyImage
            image={flagImages.uk.childImageSharp.gatsbyImageData}
            alt="UK Flag"
          />
        </a>
      </FlagWrapper>
    </Wrapper>
  )
}
